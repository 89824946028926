.strafbar {
  .row {
    height: 300px;
    @media (max-width: 767.98px) {
      height: unset;
    }
  }
  .img-part {
    height: 100%;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 767.98px) {
        max-height: 250px;
        transform: translateY(8px);
      }
    }
  }
  .content-part {
    margin: 0;
    height: 100%;
    background-color: $primary-color;
    color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;

    .content {
      padding: 1rem 20px;
      a {
        font-size: 1.3rem;
      }
    }
    @media (max-width: 575.98px) {
      font-size: 1.3rem;
    }
  }
}
