// Utilies
// fixed scrolling Traversy Media
.home-img-attachment {
  position: relative;
  // background: url('../img-background/karriere-1-img-attachment.jpg') no-repeat
  //   center center/cover;
  min-height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  .has-overlay::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.1);
  }
  // @media (max-width: 767.98px) {
  //   display: none;
  // }
  a {
    padding: 0.6rem;
    background-color: $primary-color;
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 1.8rem;
    letter-spacing: 0.25rem;
    cursor: pointer;
    transition: $transition;
    border-radius: 10px;
    z-index: 100;
    i {
      font-size: 1.6rem;
    }
    &:hover {
      background-color: lighten($primary-color, 5%);
      color: #fff;
    }
  }
  @media (max-width: 767.98px) {
    a {
      max-width: 200px;
      font-size: 1.2rem;
      padding: 0.4rem;
      i {
        font-size: 1rem;
      }
    }
  }
  @media (max-width: 575.98px) {
    a {
      max-width: 150px;
      padding: 0.2rem;
      letter-spacing: 0rem;
    }
  }
}
.home-p-divider {
  padding: 1.4rem 0rem;
  p {
    max-width: 800px;
    margin: auto;
    text-align: center;
    font-size: 2rem;
    color: $primary-color;
    font-weight: 600;
    @media (max-width: 767.98px) {
      font-size: 1.2rem;
    }
  }
}

.maps-container {
  iframe {
    width: 100%;
    height: 400px;
    border: 0px;
  }
}
