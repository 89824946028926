.glider,
.glider-contain {
  margin: 0 auto;
  position: relative;
}
.glider,
.glider-track {
  transform: translateZ(0);
}
.glider-dot,
.glider-next,
.glider-prev {
  border: 0;
  padding: 0;
  user-select: none;
  outline: 0;
}
.glider-contain {
  width: 100%;
}
.glider {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.glider-track {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 1;
}
.glider.draggable {
  user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}
.glider.draggable .glider-slide img {
  user-select: none;
  pointer-events: none;
}
.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.glider-slide {
  user-select: none;
  justify-content: center;
  align-content: center;
  width: 100%;
  min-width: 150px;
}
.glider-slide img {
  max-width: 100%;
}
.glider::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}
.glider-next,
.glider-prev {
  position: absolute;
  background: 0 0;
  z-index: 2;
  font-size: 40px;
  text-decoration: none;
  left: -23px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
  border: 2px solid $primary-color;
  // opacity: 1;
  opacity: 0.35;
  line-height: 1;
  transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67),
    color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.glider-next:focus,
.glider-next:hover,
.glider-prev:focus,
.glider-prev:hover {
  color: #ccc;
}
.glider-next {
  right: -23px;
  left: auto;
}
.glider-next.disabled,
.glider-prev.disabled {
  opacity: 0.15;
  color: #666;

  cursor: default;
}
.glider-hide {
  opacity: 0;
}
.glider-dots {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
}
.glider-dot {
  display: block;
  cursor: pointer;
  color: #ccc;
  border-radius: 999px;
  background: #ccc;
  width: 12px;
  height: 12px;
  margin: 7px;
}
.glider-dot:focus,
.glider-dot:hover {
  background: #ddd;
}
.glider-dot.active {
  background: #0b162c;
}

@media (max-width: 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px;
    display: none;
  }
  .glider::-webkit-scrollbar-thumb {
    display: none;
    opacity: 1;
    border-radius: 99px;
    background-color: rgba(156, 156, 156, 0.25);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
  }
}
