.recurring-divider {
  width: 100%;
  height: 0.75rem;
  background: linear-gradient(90deg, $primary-color 0 50%, transparent 0 100%) 0
    0 / 4rem 1rem;
  margin: 1rem 0rem;
}
hr.recurring-hr {
  width: 60px;
  color: $primary-color;
  height: 3px;
  background-color: $primary-color;
  margin: auto;
  border: none;
  margin-top: 0rem;
  margin-bottom: 0.4rem;
}
br.recurring {
  content: " ";
  display: block;
  margin: 1em;
}
