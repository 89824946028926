$full-screen-slides-height: 250px;
$full-screen-slides--news-height: 320px;
.full-screen-slides {
  .super-title {
    text-align: center;
    color: $primary-color;
    font-size: 2.4rem;
    font-weight: 600;
    padding: 1rem 12px;
    @media (max-width: 767.98px) {
      font-size: 2rem;
    }
  }

  .handle-button {
    cursor: pointer;
    transition: $transition;
    z-index: 200;
    font-size: 1.75rem;
    color: #fff;
    opacity: 0.5;
    &:hover {
      color: lighten(#333, 10%);
      opacity: 1;
    }
  }
  #prevBtn {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
  #nextBtn {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
  .z-dots-container {
    position: absolute;
    z-index: 200;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    i {
      transition: $transition;
      font-size: 0.75rem;
      color: #fff;
      margin: 0 0.1rem;
      opacity: 0.5;
      &.active {
        color: #333;
      }
    }
  }
  .slides-container {
    display: flex;
    position: relative;
    width: 100%;
    height: $full-screen-slides-height;
    overflow: hidden;
  }
  .one-box {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in;
    pointer-events: none;
    z-index: 10;
    &.active {
      pointer-events: all;
      z-index: 100;
    }
  }

  .left-part,
  .right-part {
    width: 50%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .left-part {
    background-color: $primary-color;
    color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
    .content {
      padding: 1rem 20px;
    }
    .title {
      font-family: $font-family-1;
      font-size: 1.8rem;
      font-weight: 600;
    }
    p,
    a {
      font-size: 1.25rem;
    }
  }
  &--news {
    .slides-container {
      height: $full-screen-slides--news-height;
      overflow: hidden;
      @media (max-width: 575.98px) {
        height: 300px;
      }
    }

    .news-title {
      color: $color-24;
      font-size: 2rem;
      font-weight: 600;
      font-family: $font-family-1;
    }
  }
  @media (max-width: 767.98px) {
    .left-part {
      width: 100%;
      justify-content: flex-start;
    }

    .right-part {
      display: none;
    }
  }

  @media (max-width: 575.98px) {
    .slides-container {
      height: 300px;
    }
  }

  @keyframes active-right {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes inactive-left {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes inactive-right {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @keyframes active-left {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
}
