.drei-punkte {
  margin: 4rem 0rem;

  .img-container {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      height: 200px;
      width: auto;
      object-fit: scale-down;
    }
    @media (max-width: 767.98px) {
      height: 250px;
      img {
        height: 150px;
      }
    }
  }
  .relative-container {
    position: relative;
    height: 350px;
    img.number {
      position: absolute;
      height: 150px;
      width: auto;
      object-fit: scale-down;
      z-index: -1;
    }
    .content {
      position: absolute;
      height: 100%;
      width: 100%;
      margin-top: 70px;
    }
    ul {
      padding-top: 1.5rem;
      li {
        display: flex;
        padding-bottom: 0.75rem;
      }
      span {
        display: block;
      }
      .img-part {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 30px;
          width: auto;
        }
      }
      .text-part {
        font-size: 1.5rem;
        font-weight: 600;
        margin-left: 1rem;
      }
    }
  }
}
