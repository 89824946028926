$primary-color: #0055a5;
$secondary-color: #f8f9fa;
$third-color: lighten($primary-color, 20%);
$fourth-color: #23a3e0;
$footer-color: #445b80;

$button-color: $fourth-color;
$border-color: lighten(#333, 60%);

$test-color: #e15609;
$background-color: #f5f5f5;

$dark-color: #0a1726;
$gray-color: #808080;
$light-color: #f4f4f4;

$red-color: #dc3545;
$blue-color: #1a73e8;
$green-color: #28a745;
$orange-color: #ffa500;

$gray-color-1: #fbfbfb;
$gray-color-2: #ececec;
$primary-color-15: rgba($primary-color, 0.15);

$primary-overlay: lighten($primary-color, 20%);

$background-color-2: rgb(255, 253, 234); // #FFFDEA
$color-24: #ffa6a6;

//Google Fonts

@font-face {
  font-family: 'Poppins';
  src: url('/src/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Racing Sans One';
  src: url('/src/fonts/Racing_Sans_One/RacingSansOne-Regular.ttf') format('truetype');
}

$transition: all 0.25s ease-out;
$font-family: 'Poppins', Arial, Helvetica, sans-serif;
$font-family-1: 'Racing Sans One', Arial, Helvetica, sans-serif;
$main-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.15),
  0 8px 8px rgba(0, 0, 0, 0.15);
