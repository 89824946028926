@import "allfiles";
@import "../assets/main.scss";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #ffffff;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
a {
  text-decoration: none;
  color: #333;
  &:hover {
    text-decoration: none;
  }
}

ul {
  list-style: none;
}

.mobile-overflow-wrapper {
  overflow-x: hidden;
  position: relative;
}
