input:focus,
textarea:focus {
  outline: none;
}

input,
textarea {
  font-family: "Helvetica Neue", Verdana, Arial, sans-serif;
}
input.normal {
  padding: 0.5rem 0.4rem;
  font-size: 1rem;
  border: solid 1px $border-color;
  transition: all 0.4s ease-in;
  &::placeholder {
    color: $gray-color;
  }
  &:focus {
    box-shadow: 0 0 5px rgba($primary-color, 0.8);
    border: 1px solid rgba($primary-color, 0.8);
  }
  &.error {
    border: 0.5px solid $red-color;
  }
}

input.input-100,
textarea.input-100 {
  width: 100%;
}

textarea.normal {
  padding: 0.6rem 0.2rem;
  font-size: 1rem;
  transition: all 0.4s ease-in;
  resize: none;
  &::placeholder {
    color: #333;
  }
  &:focus {
    box-shadow: 0 0 5px rgba($primary-color, 0.8);
    border: 1px solid rgba($primary-color, 0.8);
  }
  &.error {
    border: 0.5px solid $red-color;
  }
}
