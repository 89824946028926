.kontakt {
  position: relative;
  // background-image: url("/img/home/kontakt.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  //   min-height: 100vh;
  padding: 6rem 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-attachment: fixed;
  @media (max-width: 575.98px) {
    // background: #ffffff;
    height: unset;
    padding: 3rem 0rem;
  }
  .content-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
  }
  .kontakt-container {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 2rem 2%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    @media (max-width: 1199.98px) {
      margin: auto;
    }
    @media (max-width: 767.98px) {
      max-width: 600px;
      flex-direction: column;
      align-items: center;
    }
  }

  .kontakt-form {
    max-width: 600px;
    margin: auto;
  }

  .title {
    text-transform: uppercase;
    color: $primary-color;
    font-size: 2.5rem;
    text-align: center;
    font-family: $font-family-1;
    font-weight: 600;
    padding-bottom: 0.5rem;
  }
  p {
    font-size: 1.2rem;
    color: #ffffff;
    padding-bottom: 1rem;
  }
  .link-container {
    text-align: center;
  }
  .main_error {
    color: $red-color;
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 0.4rem;
  }
  .input-container {
    padding-bottom: 0.8rem;
    input {
      width: 100%;
      border: none;
      border-bottom: 3px solid #000000;
      background: #333;
      color: #ffffff;
      font-size: 1.1rem;
      padding: 0.5rem 0.4rem;
      &::placeholder {
        color: #ffffff;
      }
      &:focus {
        // box-shadow: 0 0 5px rgba($primary-color, 0.8);
        border: unset;
        border-bottom: 3px solid rgba($primary-color, 0.8);
      }
      &.error {
        border: 1px solid $red-color;
      }
    }

    textarea {
      color: #ffffff;
      width: 100%;
      height: 160px;
      padding: 0.6rem 0.2rem;
      border: none;
      font-size: 1.1rem;

      border-bottom: 3px solid #000000;
      background: #333;
      &::placeholder {
        color: #ffffff;
      }
      &:focus {
        // box-shadow: 0 0 5px rgba($primary-color, 0.8);
        border: unset;
        border-bottom: 3px solid rgba($primary-color, 0.8);
      }
    }
  }
  .law-container {
    display: flex;
    padding-top: 1rem;
    font-size: 0.9rem;
    color: #ffffff;
    a {
      font-size: 0.9rem;
      color: #ffffff;
      transition: $transition;
      &:hover {
        color: darken($primary-color, 10%);
      }
    }
    small {
      color: $red-color;
      opacity: 0;
    }
    &.error {
      small {
        opacity: 1;
        display: block;
      }
    }
  }
}
