$homeKontaktColor: darken($primary-color, 20%);
.home-kontakt {
  background-color: $homeKontaktColor;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding: 1rem 12px;
  margin-top: 4rem;
  margin-bottom: 4rem;
  @media (min-width: 1300px) {
    // .container-z {
    //   max-width: 1220px !important;
    // }
  }

  .top-box {
    display: flex;
    height: 320px;

    .left-side {
      width: 65%;
      .content {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 1rem 12px;
      }
    }
    .right-side {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35%;
      overflow: hidden;
      img {
        height: 200px;
        height: 200px;
        object-fit: cover;
        transition: transform 0.7s ease;
      }
      @media (max-width: 991.98px) {
        padding: 2rem 0rem;
        height: 300px;
        width: 300px;
      }
      @media (max-width: 500.98px) {
        padding: 2rem 0rem;
        height: 250px;
        width: 250px;
      }
    }

    @media (max-width: 991.98px) {
      height: unset;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .left-side {
        width: 100%;
        max-width: 650px;
        height: 320px;
        @media (max-width: 575.98px) {
          height: 400px;
        }
      }
      .right-side-small {
        display: block;
        // overflow: hidden;
        height: 300px !important;
        max-width: 650px;
        img {
          cursor: pointer;

          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: transform 0.7s ease;

          &:hover {
            transform: scale(1.03);
          }
        }
      }
    }
  }
  .small-text {
    color: #fff;
    font-size: 1.2rem;
  }
  .big-text {
    color: #fff;
    font-size: 1.6rem;
  }
  .btn-service {
    background-color: $homeKontaktColor;
    transition: $transition;
    font-size: 1.3rem;
    border: 1px solid #fff;
    border-radius: 4px;
    padding-left: 1.5rem;
    font-weight: unset;
    i {
      color: $homeKontaktColor;
      font-size: 1.2rem;
      transition: $transition;
      transform: translateY(2px);
    }
    &:hover {
      background-color: lighten($homeKontaktColor, 30%);
      border: 1px solid lighten($homeKontaktColor, 30%);
      i {
        color: #fff;
      }
    }
  }
}
