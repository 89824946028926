.home-categories {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  .glider-contain {
    .glider {
      padding: 2rem 0rem;
    }
    .one-icon {
      background: #fff;
      padding: 2rem 12px;
      transition: all 0.8s ease-out;
      color: #fff;
      background-color: $primary-color;
      &:nth-child(even) {
        background-color: $fourth-color;
      }

      .text-part {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        max-width: 300px;
        margin: auto;
      }
      .title-text {
        font-family: $font-family-1;
        font-size: 1.8rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
      }
      .name {
        font-size: 1.3rem;
      }
      .uebergang {
        font-weight: 600;
        font-size: 1.75rem;
      }
    }
    .glider-prev,
    .glider-next {
      font-size: 1.5rem;
      background: #333333;
      color: #ffffff;
      margin-top: 1rem;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      border-radius: 50%;

      .fas {
        margin-left: 1rem;
      }
    }
    .glider-prev {
      margin-left: 1.5rem;
    }
    .glider-next {
      margin-right: 1.5rem;
    }
    .dots {
      display: none;
    }
  }
}
