//  Showcase

$showcase-height: 600px;
$showcase-height-2: 500px;
$showcase-height-3: 400px;
$showcase-height-4: 380px;
$showcase-unterseite: 400px;
.showcase {
  width: 100%;
  margin-top: -90px;
  height: $showcase-height;
  position: relative;

  text-align: center;

  .overlay {
    width: 100%;
    height: $showcase-height;
    background-color: rgba(#333, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: $showcase-height;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    // opacity: 0.9;
  }

  h3 {
    display: none;
  }

  &.showcase_unterseite {
    height: $showcase-unterseite !important;
    .overlay {
      height: $showcase-unterseite !important;
    }
    img {
      height: $showcase-unterseite !important;
    }
    h2 {
      margin: 120px !important;
    }
  }
  h2 {
    padding: 0 12px;
    margin-top: 210px;
    color: #ffffff;
    text-align: center;
    .h2-fett {
      font-size: 3.2rem;
      font-weight: 600;
    }
    .h2-klein {
      margin-top: 1rem;
      display: block;
      min-height: 70px;
      font-size: 2.5rem;
      font-family: $font-family-1;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 5px;
        height: 98%;
        background: #ffffff;
        margin: 0 0 0 1px;
        bottom: 10%;
        animation: blink 0.6s none infinite;
        animation-delay: 0s;
      }
    }
    @media (max-width: 991.98px) {
      .h2-fett {
        font-size: 2.5rem;
      }
    }
    @media (max-width: 767.98px) {
      .h2-fett {
        font-size: 2.2rem;
      }
      .h2-klein {
        min-height: 60px;

        font-size: 2rem;
      }
    }
    @media (max-width: 575.98px) {
      .h2-fett {
        font-size: 1.8rem;
      }
      .h2-klein {
        min-height: 40px;

        font-size: 1.5rem;
      }
    }
  }

  a.btn {
    padding: 1rem 2rem;
    font-size: 1.3rem;
    @media (max-width: 767.98px) {
      padding: 0.75rem 1.5rem;
      font-size: 1.2rem;
    }
  }
  @media (max-width: 991.98px) {
    height: $showcase-height-2;
    .overlay {
      height: $showcase-height-2;
    }
    img {
      height: $showcase-height-2;
    }
    h2 {
      margin-top: 180px;
    }
  }
  @media (max-width: 767.98px) {
    height: $showcase-height-3;
    .overlay {
      height: $showcase-height-3;
    }
    img {
      height: $showcase-height-3;
    }
    h2 {
      margin-top: 150px;
    }
  }

  @media (max-width: 575.98px) {
    height: $showcase-height-4;
    .overlay {
      height: $showcase-height-4;
    }
    img {
      height: $showcase-height-4;
    }
    h2 {
      margin-top: 125px;
    }
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
