#lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .prev,
  .next,
  .close {
    position: absolute;
    font-size: 1.6rem;
    color: #333;
    cursor: pointer;
    transition: all 0.2 ease-in;
    &:hover {
      color: lighten(#333, 10);
    }
  }
  .close {
    top: 2%;
    left: 2%;
  }

  .prev {
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
  }
  .next {
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
  }

  img {
    width: auto;
    height: auto;
    max-width: 900px;
    max-height: 95%;
    padding: 4px;
    object-fit: cover;
    background-color: black;
    border: 2px solid white;
  }

  .content-container {
    position: absolute;
    bottom: 2%;
    width: 100%;
    text-align: center;
  }
  .alt-text {
    margin: auto;
    max-width: 600px;
    padding: 0.4rem 0.8rem;
    border-radius: 12px;
    color: #fff;
    font-size: 1.2rem;
    background: rgba(#333, 0.8);
  }
  @media (max-width: 900.98px) {
    img {
      max-width: 95%;
    }
  }

  @media (max-width: 576.98px) {
    .alt-text {
      max-width: 300px;
      font-size: 1rem;
    }
  }
}
