.leistungen {
  padding-top: 4rem;

  padding-bottom: 6rem;
  .title-script {
    color: $primary-color;
    font-family: $font-family-1;
    font-size: 3.2rem;
    font-weight: 600;
    text-align: center;
    display: block;
  }

  .sub-title {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .h1-part-2 {
    display: block;
    text-align: center;
  }

  ul {
    font-size: 1.3rem;
    h2 {
      font-size: 1.3rem;
      font-weight: unset;
    }
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 0.75rem;
      span {
        display: block;
      }
      i {
        font-size: 0.8rem;
        color: $primary-color;
        margin-right: 0.5rem;
        transform: translateY(-2px);
      }
    }
  }
}

.special-background {
  background-image: linear-gradient(
      45deg,
      transparent 0%,
      transparent 55%,
      rgba(64, 64, 64, 0.04) 55%,
      rgba(64, 64, 64, 0.04) 76%,
      transparent 76%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 14%,
      rgba(64, 64, 64, 0.04) 14%,
      rgba(64, 64, 64, 0.04) 41%,
      transparent 41%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 2%,
      rgba(64, 64, 64, 0.04) 2%,
      rgba(64, 64, 64, 0.04) 18%,
      transparent 18%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 61%,
      rgba(64, 64, 64, 0.04) 61%,
      rgba(64, 64, 64, 0.04) 71%,
      transparent 71%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}
