.color-24 {
  color: $color-24;
}
.color-1 {
  color: $primary-color;
}
.title-24-1 {
  font-size: 2rem;
  font-family: $font-family-1;
  font-weight: 600;
}
