$loader-transition: all 0.2s ease-in;
$hollowBoxSize_Loader: 3em;
$hollowLight_Loader: #ececec;
$hollowDark_Loader: $primary-color;
$hollowTiming_Loader: 1.25s;
#loader-in-handlers {
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.6s ease-in;
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -10;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $loader-transition;
  &.active {
    z-index: 1000;
    opacity: 0.8;
    pointer-events: all;
    .loader,
    .circle {
      opacity: 1;
    }
  }
  .hollowLoader {
    width: $hollowBoxSize_Loader;
    height: $hollowBoxSize_Loader;
    animation: loaderAnim $hollowTiming_Loader infinite ease-in-out;
    outline: 1px solid transparent;
    z-index: 1000;
    .largeBox {
      height: $hollowBoxSize_Loader;
      width: $hollowBoxSize_Loader;
      background-color: $hollowLight_Loader;
      outline: 1px solid transparent;
      position: fixed;
    }
    .smallBox {
      height: $hollowBoxSize_Loader;
      width: $hollowBoxSize_Loader;
      background-color: $hollowDark_Loader;
      position: fixed;
      z-index: 1;
      outline: 1px solid transparent;
      animation: smallBoxAnim $hollowTiming_Loader alternate infinite
        ease-in-out;
    }
  }

  @keyframes smallBoxAnim {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(0.75);
    }
  }

  @keyframes loaderAnim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
}
