.standort {
  min-height: 500px;
  // background-color: $gray-color-1;

  .img-col {
    padding: 0 16px;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    border: 0px solid !important;
    @media (max-width: 575.98px) {
      min-height: 300px;
    }
    #OpenStreetMap {
      width: 100%;
      height: 100%;
      min-height: 500px;
      object-fit: scale-down;
      margin-right: auto;
      // pointer-events: none;
      @media (max-width: 400.98px) {
        margin: auto;
        width: 95%;
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      margin-right: auto;
      pointer-events: none;
      @media (max-width: 400.98px) {
        margin: auto;
        width: 95%;
      }
    }
  }
  .title {
    font-family: $font-family-1;
    font-size: 2.5rem;
  }
  .content {
    padding: 2rem 12px;
    max-width: 550px;
    margin: auto;
  }

  .aufklapp-container {
    min-height: 100px;
  }
  .aufklapp {
    background-color: $primary-color;
    max-width: 300px;
    border-radius: 12px;
    color: #fff;
    padding: 0.75rem;
    font-size: 1.1rem;
    cursor: pointer;
    img {
      height: 40px;
      width: 40px;
      object-fit: scale-down;
      transform: translateY(2px);
    }
    .first-col {
      display: flex;
      align-items: center;
    }
    .hover-el {
      max-height: 0;
      transition: max-height 0.6s ease-out;
      overflow: hidden;
    }
    &:hover {
      .hover-el {
        max-height: 150px;
        transition: max-height 0.6s ease-in;
      }
    }
  }
  .zeiten {
    font-size: 1.2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    a {
      font-size: 1.2rem;
      transition: $transition;
      &:hover {
        color: $primary-color;
      }
    }
    @media (max-width: 575.98px) {
      flex-direction: column;
      align-items: flex-start;
      .one-zeit {
        padding-bottom: 2rem;
      }
    }
  }
}
